<template>
  <v-dialog
    v-model="modal"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="scale-transition"
    persistent
    scrollable
    max-width="800px"
  >
    <v-card outlined>
      <v-card-title class="subtitle-1 font-weight-bold">
        {{ $t("routes.user_access") }}
        <div style="position: absolute; right: 25px;">
          <v-icon :disabled="loading" @click="cancel">mdi-close</v-icon>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text style="height: 700px">
        <v-tabs v-model="tab" show-arrows>
          <v-tab v-for="route in accessList" :key="route.id">
            {{ route.name }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(route, i) in accessList" :key="route.id">
            <v-card flat>
              <v-treeview
                v-model="route.kodes"
                :items="[accessList[i]]"
                open-all
                item-key="kode"
                item-disabled="locked"
                dense
                selectable
                selected-color="primary"
                open-on-click
              />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <!-- <v-treeview
          v-model="requestBody.kodes"
          :items="accessList"
          item-key="kode"
          item-disabled="locked"
          selectable
          selected-color="primary"
          rounded
          dense
          open-on-click
        /> -->
      </v-card-text>
      <v-divider />
      <v-card-actions class="mb-3 mr-3">
        <v-spacer />
        <v-btn outlined color="primary" @click="cancel">{{
          $t("app.cancel")
        }}</v-btn>
        <v-btn
          :loading="loading"
          class="gradient-primary"
          dark
          depressed
          @click="submit"
          >{{ $t("app.save") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    modal: Boolean,
    requestBody: Object,
    loading: Boolean,
    accessList: Array
  },
  watch: {
    modal() {
      this.tab = null;
    }
  },
  data() {
    return {
      tab: null
    };
  },
  methods: {
    submit() {
      this.$emit("save");
    },
    cancel() {
      this.tab = null;
      this.$emit("close");
    }
  }
};
</script>
